import React from "react";
import { useForm } from "react-hook-form";
import { TextInputSanity } from "./inputs/TextInput"
import { TextAreaSanity } from "./inputs/TextArea"
import { CheckboxSanity } from "./inputs/Checkbox"
import GenericNetlifyForm from "./GenericNetlifyForm"

const ContactForm = ({ data, pageSlug }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <GenericNetlifyForm formName={`contact-${data.name}__${pageSlug}`} data={data} submitHandler={handleSubmit}>
      {data.precedingInputs && data.precedingInputs.map((input, id) => resolveInputTypeAndRender(input, register, errors, id))}
      <TextInputSanity
        sanityData={data.emailInput}
        register={register}
        errors={errors}
      />
      <TextAreaSanity
        sanityData={data.messageArea}
        register={register}
        errors={errors}
      />
      {data.succeedingInputs && data.succeedingInputs.map((input, id) => resolveInputTypeAndRender(input, register, errors, id))}
      <button type="submit" style={{ background: data.button.color.hex, color: '#FFFFFF' }} className="submit-button">
          {data.button.cta}
      </button>
    </GenericNetlifyForm>
  );
}

export default ContactForm

const resolveInputTypeAndRender = (input, register, errors, id) => { 
  switch (input._type) {
    case 'textInput': return (<TextInputSanity sanityData={input} key={id}
      register={register}
      errors={errors}
    />)
    case 'checkbox': return (<CheckboxSanity sanityData={input} key={id}
      register={register}
      errors={errors}
    />)
    case 'textArea': return (<TextAreaSanity sanityData={input} key={id}
      register={register}
      errors={errors}
    />)
  }
}

//bot-field=&form-name=contact&main+email=a%40aaaa.ca
//bot-field: 
//form-name: contact
//main email: a@aaaa.ca

//form-name=contact&main%20email=a%40aaaa.dk
//form-name: contact
//main%20email: a%40b.news