import React from "react"
import Image from "gatsby-plugin-sanity-image"
import getYouTubeId from 'get-youtube-id';
import ContactForm from "../../components/forms/ContactForm"
import EmailSignup from "../../components/forms/EmailSignup"
import {GatsbyImage} from 'gatsby-plugin-image'
import {getGatsbyImageData} from 'gatsby-source-sanity'
import {PortableTextWithRenderers} from "./PortableTextWithRenderers"

import { PortableText } from '@portabletext/react'

const sanityConfig = {projectId: process.env.SANITY_PROJECT_ID, dataset: 'production'}

export const SeoFriendlyImageRenderer = ({value}) => {
  const imageData = getGatsbyImageData(value.asset.id, {maxWidth: 720}, sanityConfig)

  return (
    <div style={{display:"flex"}}>
      <figure className="post-figure">
        <div style={{display:"flex"}}>
        <GatsbyImage image={imageData}
          style={{
            maxWidth: `${Math.min(720, value.asset.metadata.dimensions.width / 2)}px`, // divided by 2 for retina optimized images
            objectFit: "contain",
            margin: "auto"
          }}
        />
        </div>

        <figcaption>
          {value.caption} {value.author != null && `, © ${value.author}`}
        </figcaption>
      </figure>
    </div>
  )
}

export const YoutubeRenderer = ({value}) => {
  // Fall back to default handling
  return <div className="youtube-container-100">
          <iframe src={`https://www.youtube.com/embed/${getYouTubeId(value.url)}`} allowFullScreen="allowfullscreen">
          </iframe>
         </div>
}

export const ContactFormRenderer = ({value}, pageSlug) => {
  const contactForm = value

  return (
    <ContactForm data={contactForm} pageSlug={pageSlug} />
  )
}



export const EmailSignupRenderer = ({value}, pageSlug) => {
  return (
    <EmailSignup data={value} pageSlug={pageSlug} />
  )
}

export const CustomHtmlRenderer = ({value}) => {
  return (
    <div dangerouslySetInnerHTML={{__html: value.htmlCode}} >
    </div>
  )
}

export const SideBySideBoxRenderer = ({value}, siteSettings) => {
  return (
    <div className="sidebyside-box">
      <div className="sidebyside-item">
        <PortableTextWithRenderers value={value.left} siteSettings={siteSettings} />
      </div>
      <div className="sidebyside-item">
        <PortableTextWithRenderers value={value.right} siteSettings={siteSettings} />
      </div>
    </div>
  )
}

export const TableRenderer = ({value}) => {
  const table = value
  return (
    table == null || table.rows == null ? null :
    <div className="rounded-table-container">
      <table className="table">
        {table.rows.map(row =>
            <tr className="tr">
              {row.cells.map(cell =>
                <td className="td">{cell}</td>
              )}
            </tr>
        )}
      </table>
    </div>
  )
}