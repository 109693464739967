import _ from 'lodash'
import PropTypes from 'prop-types'

export const getAuthorProperties = (primaryAuthor) => {
    let authorProfiles = []

    authorProfiles.push(
        primaryAuthor.website ? primaryAuthor.website : null,
        primaryAuthor.socialmedialinks?.twitter ? primaryAuthor.socialmedialinks.twitter : null,
        primaryAuthor.socialmedialinks?.facebook ? primaryAuthor.socialmedialinks.facebook : null,
        primaryAuthor.socialmedialinks?.instagram ? primaryAuthor.socialmedialinks.instagram : null,
        primaryAuthor.socialmedialinks?.linkedin ? primaryAuthor.socialmedialinks.linkedin : null,
        primaryAuthor.socialmedialinks?.youtube ? primaryAuthor.socialmedialinks.youtube : null,
        primaryAuthor.socialmedialinks?.pinterest ? primaryAuthor.socialmedialinks.pinterest : null,
    )

    authorProfiles = _.compact(authorProfiles)

    return {
        name: primaryAuthor.name || null,
        sameAsArray: authorProfiles.length ? authorProfiles : null, //`["${_.join(authorProfiles, `", "`)}"]` : null,
        image: primaryAuthor.image || null,
        facebook: primaryAuthor.socialmedialinks?.facebook ? primaryAuthor.socialmedialinks.facebook : null,
        twitter: primaryAuthor.socialmedialinks?.twitter ? primaryAuthor.socialmedialinks.twitter : null,
    }
}

getAuthorProperties.defaultProps = {
    fetchAuthorData: false,
}

getAuthorProperties.PropTypes = {
    primaryAuthor: PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.object,
        twitter: PropTypes.string,
        facebook: PropTypes.string,
    }).isRequired,
}

export default getAuthorProperties
