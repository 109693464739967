import React from "react";

export const TextInputSanity = ({sanityData, register, errors, className, additionalStyle}) => {
  return (
    <TextInput 
      name={sanityData.name} 
      label={sanityData.label} 
      placeholder={sanityData.placeholder} 
      required={sanityData.required}
      type={sanityData.type} 
      emptyError={sanityData.emptyError}
      invalidValueError={sanityData.invalidValueError}
      register={register}
      errors={errors}
      className={className}
      additionalStyle={additionalStyle}
    />
  )
}

export const TextInput = ({name, label, placeholder, type, required, emptyError, invalidValueError, register, errors, className, additionalStyle}) => {
  let validations = {}
  if (required) {
    validations = { required: true }
  }

  if (type == 'email') {
    validations = { ...validations, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }
  }
  
  return (
    <>
      {label != null ? <label htmlFor={name}>{label}</label> : null}
      <input type={type} {...register(name, validations)} placeholder={placeholder} 
        className={className? className : "text-input"} style={{ ...additionalStyle && additionalStyle }}
      />
      {errors[name]?.type === 'required' && <span className="error-text">{emptyError}</span>}
      {errors[name]?.type === 'pattern' && <span className="error-text">{invalidValueError}</span>}
      <br/>
    </>
  )
}