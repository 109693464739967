import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { PortableTextWithRenderers } from "./PortableTextWithRenderers"

export const BlockSerializer = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query BlockSerializerQuery {
          allSanitySiteSettings {
            edges {
              node {
                tagsPage {
                  slugWithRedirectValidation {
                    ...SlugWithRedirectValidationFields
                  }
                }
                useCategoriesRoot
                authorSlug {
                  ...SlugWithRedirectValidationFields
                }
              }
            }
          }
        }
      `}

      render={data => (
        <PortableTextWithRenderers 
          value={props.children} 
          siteSettings={data.allSanitySiteSettings.edges[0].node}
          currentPageSlugForTracking={props.pageSlug}
        />
      )}
    />
  )
}


