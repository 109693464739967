import React from "react";

export const TextAreaSanity = ({sanityData, register, errors}) => {
  return (
    <TextArea 
      name={sanityData.name}
      label={sanityData.label}
      placeholder={sanityData.placeholder}
      required={sanityData.required}
      emptyError={sanityData.emptyError}
      register={register}
      errors={errors}
    />
  )
}

export const TextArea = ({name, label, placeholder, required, emptyError, register, errors}) => {
  let validations = {}
  if (required) {
    validations = { required: true }
  }

  return (
      <label htmlFor={name}>
        {label != null ? <span>{label}</span> : null}
        <textarea {...register(name, validations)} placeholder={placeholder} className="text-input" />
        {errors[name]?.type === 'required' && <p aria-live="polite" className="error-text">{emptyError}</p>}
      </label>
  )
}