import React from "react"
import { generateHtmlTagFromLink, generateHtmlTagFromReference } from "./../urlFromSanity"
import { PortableText } from '@portabletext/react'
import {
  SeoFriendlyImageRenderer,
  YoutubeRenderer,
  CustomHtmlRenderer,
  ContactFormRenderer,
  EmailSignupRenderer,
  TableRenderer,
  SideBySideBoxRenderer,
} from "./BlockRenderers"

export const PortableTextWithRenderers = ({value, siteSettings, currentPageSlugForTracking}) => {
  return (
    <PortableText 
      value={value} 
      components={
        serializer(siteSettings, currentPageSlugForTracking)
      }
    />
  )
}

const serializer = (sanitySiteSettings, pageSlug) => {
  return ({
    /* Disable when titles or other components need to be restyled 
    block: {
      // Customize block types with ease
      h1: ({children}) => <h1 className="text-2xl">{children}</h1>,
      //blockquote: ...
    }
    */
    types: {
      //block: BlockRenderer,
      seoFriendlyImage: SeoFriendlyImageRenderer,
      youtube: YoutubeRenderer,
      contactForm: (props) => ContactFormRenderer(props, pageSlug),
      emailSignup: (props) => EmailSignupRenderer(props, pageSlug),
      customHtml : CustomHtmlRenderer,
      sideBySideBox: (props) => SideBySideBoxRenderer(props, sanitySiteSettings),
      table: TableRenderer,
    },
    marks: {
      internalLink: ({ value, children }) => {
        const { reference = {} } = value
        if (reference == null) {
          return null
        }

        return generateHtmlTagFromReference(value, children, null, null, sanitySiteSettings)
      },
      link: ({ value, children }) => {
        return generateHtmlTagFromLink(value, children, null, null)
      }
    }
  })
}