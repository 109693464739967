import React from "react";
import { useForm } from "react-hook-form";
import { TextInputSanity } from "./inputs/TextInput"
import { CheckboxSanity } from "./inputs/Checkbox"
import GenericNetlifyForm from "./GenericNetlifyForm"
import { BlockSerializer } from "../../utils/portableText/BlockSerializer"

const EmailSignUp = ({ data, pageSlug }) => {
  const { register, handleSubmit, formState: { errors } } = useForm()

  // TODO: currently footerText is rendered just as simple text. For example links are ingored. The reason is we don't have _raw[fieldnames]
  // here. Possible workaround for future will be to fecth data from root allEmailSignup objects
  const buttonStyle={ background: data.button.color.hex, color: '#FFFFFF' }
  const horizontalInputStyle={ borderColor: data.button.color.hex }

  return (
    <div>
      <GenericNetlifyForm formName={`email-${data.name.current}__${pageSlug}`} data={data} submitHandler={handleSubmit}>
        {data.fieldStyle == "vertical" ? 
          <div>
            <h2>{data.title}</h2>
            <h3>{data.subtitle}</h3>
            <ol>
              {data.benefits && data.benefits.map((item, id) => <li key={id}>{item}</li>)}
            </ol>
            {data.additionalInputs && data.additionalInputs.map((input, id) => (
              <TextInputSanity sanityData={input} key={id}
                register={register}
                errors={errors}
              />)
            )}
            <TextInputSanity sanityData={data.emailInput}
              register={register}
              errors={errors}
            />
            {data.additionalConsent && <CheckboxSanity sanityData={data.additionalConsent[0]}
              register={register}
              errors={errors}
            />}
            <button type="submit" style={buttonStyle} className="submit-button">
                {data.button.cta}
            </button>
            <div className="footer-text"><small><BlockSerializer>{data.footerText}</BlockSerializer></small></div>
          </div>
          :
          <div className="justify-center">
            <div>
              <h2>{data.title}</h2>
              <h3>{data.subtitle}</h3>
              <ol>
                {data.benefits && data.benefits.map((item, id) => <li key={id}>{item}</li>)}
              </ol>
              {data.additionalInputs && data.additionalInputs.map((input, id) => (
                <div>
                  <TextInputSanity sanityData={input} key={id}
                    register={register}
                    errors={errors}
                  />
                </div>)
              )}
              <div className="horizontal">
                <div>
                  <TextInputSanity sanityData={data.emailInput} className="horizontal-input" additionalStyle={horizontalInputStyle}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div>
                  <button type="submit" style={buttonStyle} className="horizontal-button">
                      {data.button.cta}
                  </button>
                </div>
              </div>
              {data.additionalConsent && <CheckboxSanity sanityData={data.additionalConsent[0]}
                register={register}
                errors={errors}
              />}
              <div className="footer-text"><small><BlockSerializer>{data.footerText}</BlockSerializer></small></div>
            </div>
          </div> 
        }
      </GenericNetlifyForm>
    </div>
  );
}

export default EmailSignUp