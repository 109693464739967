import React from "react";

export const CheckboxSanity = ({sanityData, register, errors, className}) => {
  return (
    <Checkbox 
      name={sanityData.name}
      label={sanityData.label}
      checked={sanityData.checked}
      required={sanityData.required}
      notCheckedError={sanityData.notCheckedError}
      register={register}
      errors={errors}
      className={className}
    />
  )
}

export const Checkbox = ({name, label, checked, required, notCheckedError, register, errors}) => {
  let validations = {}
  if (required) {
    validations = { required: true }
  }

  return (
    <label htmlFor={name}>
      <input type="checkbox" {...register(name, validations)} className="checkbox" defaultChecked={checked}/>
      <span><small>{label}</small></span>
      <br/>
      {errors[name]?.type === 'required' && <span className="error-text">{notCheckedError}</span>}
      <br/>
    </label>
  )
}