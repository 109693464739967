import React, { useState } from "react";
import { navigate } from "gatsby";
const { getSlugFromRedirectValidatingDocument } = require(`../../utils/urlProvider`)

const GenericNetlifyForm = ({formName, data, submitHandler, children}) => {

  const [submitted, setSubmitted] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')

  const handleSuccess = () => {
    switch(data.formAction.type) {
      case 'onPage': {
        setSubmitted(true)
        setSuccessful(true)
        setStatusMessage(data.formAction.successMesssage)
        break
      }
      case 'redirect': navigate(`/${getSlugFromRedirectValidatingDocument(data.formAction.successPageReference.reference).current}`)
    }
  }

  const handleFailure = (error) => {
    console.error(error)
    switch(data.formAction.type) {
      case 'onPage': {
        setSubmitted(true)
        setSuccessful(false)
        setStatusMessage(data.formAction.errorMesssage)
        break
      }
      case 'redirect': navigate(`/${getSlugFromRedirectValidatingDocument(data.formAction.failurePageReference.reference).current}`)
    }
  }

  const handleSubmitToNetlify = (data, e) => {
    e.preventDefault()
    let formData = new FormData();
    formData.append('form-name', formName)
    Object.keys(data).forEach( key =>
      formData.append(key, data[key])
    )

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    })
      .then(response => handleSuccess())
      //.then(response => { window.location.href = 'https://url-you.want.com }}))
      .catch((error) => handleFailure(error));
  }

  return (
    !submitted ?
      <form name={formName} method="POST" data-netlify="true" netlify-honeypot="bot-field"
        onSubmit={submitHandler(handleSubmitToNetlify)} className="form-container" noValidate>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value={formName}/>
        {children}
      </form>
      :
    successful ? <div className="form-submission-success-box">{statusMessage}</div> 
    : <div className="form-submission-failure-box">{statusMessage}</div>
  );
}

export default GenericNetlifyForm